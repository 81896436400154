<template>
    <div class="number-spinner">
        <div class="d-flex justify-content-end justify-content-md-start">
            <button class="btn number-spinner__button" type="button"
                    :aria-label="translate('number-spinner.sr-decrease-value')"
                    @click="decreaseValue"
                    :disabled="value === 0">
                -
            </button>

            <input type="number"
                   class="number-spinner__input"
                   v-model="value" readonly
                   :name="inputName" :id="inputId" pattern="[0-9]*" min="0" />

            <button class="btn number-spinner__button" type="button"
                    :aria-label="translate('number-spinner.sr-increase-value')"
                    @click="increaseValue"
                    :disabled="value === +maxValue">
                +
            </button>
        </div>
    </div>
</template>

<script>
import {translate} from '@elements/translations';
import { ref } from "vue";

export default {
    name: 'NumberSpinner',
    setup() {
        const input = ref("");
        return { input };
    },
    props: {
        defaultValue: {
            type: Number,
            required: false,
            default: 0
        },
        maxValue: {
            type: Number,
            required: false,
            default: 15
        },
        inputId: {
            type: String,
            required: false,
            default: 'input-id'
        },
        inputName: {
            type: String,
            required: false,
            default: 'input-name'
        }
    },
    data() {
        return {
            value: this.defaultValue,
        }
    },
    methods: {
        increaseValue() {
            this.value++;
            this.$emit('update:modelValue', this.value)
        },
        decreaseValue() {
            this.value--;
            this.$emit('update:modelValue', this.value)
        },
        translate
    }
}
</script>